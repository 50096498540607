<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Transactions
			</v-flex>
		</v-layout>
		<Table internal :page-loading="pageLoading" type="transaction"></Table>
	</div>
</template>
<script>
import Table from "@/view/components/Table";
import ListingMixin from "@/core/mixins/listing.mixin";

export default {
	name: "transaction-internal-listing",
	title: "Listing Internal Transaction",
	mixins: [ListingMixin],
	components: {
		Table,
	},
	data() {
		return {
			pageTitle: "Transactions",
			endpoint: "order-transactions",
			internal: true,
			relation_type: null,
			relation_type_id: 0,
		};
	},
	props: {
		relationType: {
			type: String,
			default: null,
		},
		relationTypeId: {
			type: Number,
			default: 0,
		},
		noAdd: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		init() {
			this.relation_type = this.relationType;
			this.relation_type_id = this.relationTypeId;
		},
	},
	beforeMount() {
		this.init();
	},
};
</script>
