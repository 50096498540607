<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/work-order-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Work Orders
			</v-flex>
			<v-flex class="text-right">
				<v-btn color="blue darken-4 text-white" class="ml-2" depressed tile
					><v-icon small left>mdi-playlist-plus</v-icon>New Work order</v-btn
				>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 light-dark-blue-bg">#</th>
						<th class="p-2 light-dark-blue-bg">Summary</th>
						<th class="p-2 light-dark-blue-bg">Assigned To</th>
						<th class="p-2 light-dark-blue-bg">Due Date</th>
						<th class="p-2 light-dark-blue-bg">Status</th>
						<th class="p-2 light-dark-blue-bg">Work Type</th>
					</tr>
				</thead>
				<tfoot>
					<tr>
						<td colspan="6">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no work order at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
	},
};
</script>
