var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"mail-template-drawer",attrs:{"app":"","stateless":"","right":"","temporary":"","width":"50%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('v-card-title',{staticClass:"py-1 grey lighten-4 blue--text text--darken-4 text-uppercase border-bottom sticky-on-top"},[_c('div',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"h4 m-0 my-auto"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{staticClass:"darken-4",attrs:{"icon":"","tile":"","depresed":""},on:{"click":function($event){return _vm.$emit('close', true)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-card-text',{staticClass:"mb-12"},[_c('div',{staticClass:"email-template-container py-2"},[_c('v-form',{ref:"emailApprovalForm",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-row',[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label required",attrs:{"for":"email "}},[_vm._v("Recipients")])]),_c('v-col',{staticClass:"my-auto py-0",attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"d-flex align-items-end"},[_c('ComboboxInput',{staticClass:"autocomplete-multiple",class:{
										required: !_vm.recipients,
									},attrs:{"hide-details":"","items":_vm.recipientsList,"outlined":"","placeholder":"Recipients","dense":"","deletable-chips":"","small-chips":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"blue darken-4","multiple":"","rules":[_vm.vrules.required(_vm.recipients, 'Recipients')]},on:{"change":function($event){return _vm.validateEmail($event)}},model:{value:(_vm.recipients),callback:function ($$v) {_vm.recipients=$$v},expression:"recipients"}}),_c('v-btn',{attrs:{"tile":"","depressed":"","color":"green white--text"},on:{"click":function($event){_vm.isCC = !_vm.isCC}}},[_vm._v(" CC ")])],1)]),(_vm.isCC)?[_c('v-col',{staticClass:"my-auto",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label",attrs:{"for":"email "}},[_vm._v("CC")])]),_c('v-col',{staticClass:"my-auto py-0",attrs:{"cols":"12","md":"10"}},[_c('ComboboxInput',{staticClass:"autocomplete-multiple",attrs:{"hide-details":"","items":_vm.ccRecipientsList,"outlined":"","placeholder":"CC","dense":"","small-chips":"","deletable-chips":"","color":"blue darken-4","multiple":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading},on:{"change":function($event){return _vm.validateCCEmail($event)}},model:{value:(_vm.ccRecipients),callback:function ($$v) {_vm.ccRecipients=$$v},expression:"ccRecipients"}})],1)]:_vm._e(),_c('v-col',{staticClass:"my-auto",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label required",attrs:{"for":"subject "}},[_vm._v("Subject")])]),_c('v-col',{staticClass:"my-auto py-0",attrs:{"cols":"12","md":"10"}},[_c('TextInput',{class:{
									required: !_vm.subject,
								},attrs:{"hide-details":"","id":"subject","placeholder":"Subject","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.subject, 'Subject')]},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1),_c('v-col',{staticClass:"mt-3 py-0",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label required",attrs:{"for":"message"}},[_vm._v("Message")])]),_c('v-col',{staticClass:"my-auto py-0 mt-2",attrs:{"cols":"12","md":"10"}},[_c('ckeditor',{class:{
									required: !_vm.editorData,
								},attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.editorData),callback:function ($$v) {_vm.editorData=$$v},expression:"editorData"}})],1)],2),_c('v-row',[_c('v-col',{staticClass:"py-0 mt-3",attrs:{"cols":"12","md":"2"}},[_c('label',{staticClass:"btx-label mt-3",attrs:{"for":"message"}},[_vm._v("Attachments")])]),_c('v-col',{staticClass:"my-auto py-0 mt-2",attrs:{"cols":"12","md":"10"}},[_c('FileUpload',{model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1)],1)],1)]),_c('v-card-actions',{staticClass:"fixed-on-bottom grey lighten-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"blue darken-4 mr-2",attrs:{"tile":"","depressed":"","color":"white--text","disabled":_vm.pageLoading,"loading":_vm.pageLoading},on:{"click":function($event){return _vm.sendMail()}}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-send")]),_vm._v(" Send Email ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }